<template>
  <div class="relative min-h-screen">
    <main-menu class="z-30"></main-menu>

    <!-- acceptance announcement -->
    <acceptance-announcement
      :periods="acceptedPeriods"
      class="pt-24"
    ></acceptance-announcement>

    <!-- period information -->
    <period-information
      v-if="school_identity.whatsapp"
      class="pt-20"
      :period="period"
      :whatsapp="school_identity.whatsapp"
      @submitted="fetchStudentInformation"
    ></period-information>

    <!-- registration instructions -->
    <procedure-instruction
      :content="information.content"
      v-if="information.content"
    ></procedure-instruction>

    <core-form></core-form>
    <address-form></address-form>
    <parent-form></parent-form>
  </div>
</template>

<script>
import Axios from "axios";
import { mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      information: {},
      period: {},
      student: {},
      acceptedPeriods: "", // name of period which contains accepted students list.
    };
  },
  methods: {
    fetchInformation() {
      Axios.get("api/registration/information").then((response) => {
        this.information = response.data.information;
      });
    },

    fetchPeriod() {
      Axios.get("api/registration/period/current/active").then((response) => {
        this.period = response.data.period;
      });
    },

    fetchAcceptedStudents() {
      Axios.get("api/student/newly-accepted")
        .then((response) => {
          this.acceptedPeriods = response.data.periods;
        })
        .catch((error) => {
          error;
          this.$toasted.error("Tidak dapat menampilkan daftar peserta lulus");
        });
    },

    fetchStudentInformation(codes) {
      if (codes.student_code === null || codes.test_code === null) {
        this.$toasted.error(
          "Mohon diisikan kode peserta didik dan kode pendaftaran"
        );
        return;
      }

      this.$toasted.info("Sedang mengambil data peserta didik");

      Axios.post("api/student/show/by-code", codes)
        .then((response) => {
          this.student = response.data.student;
          this.SHOW_FORM("core");
          this.SET_SELECTED_STUDENT(this.student);
        })
        .catch((error) => {
          let httpStatus =
            error.response !== undefined ? error.response.status : 400;

          let message =
            httpStatus === 404
              ? "Mohon diperiksa kembali kode calon peserta didik dan kode pendaftaran"
              : "Terdapat kesalahan pada sistem kami. Mohon dicoba pada waktu lain atau hubungi staf kami.";
          this.$toasted.error(message);
        });
    },
    ...mapMutations("student", ["SHOW_FORM", "SET_SELECTED_STUDENT"]),
  },
  computed: {
    ...mapGetters(["school_identity"]),
  },
  mounted() {
    this.fetchInformation();
    this.fetchPeriod();
    this.fetchAcceptedStudents();
  },
  components: {
    MainMenu: () => import("@/components/MainMenu.vue"),
    CoreForm: () => import("@/components/Registration/CoreForm.vue"),
    AddressForm: () => import("@/components/Registration/AddressForm.vue"),
    ParentForm: () => import("@/components/Registration/ParentForm.vue"),
    ProcedureInstruction: () =>
      import("@/components/Registration/ProcedureInstruction.vue"),
    PeriodInformation: () =>
      import("@/components/Registration/PeriodInformation.vue"),
    AcceptanceAnnouncement: () =>
      import("@/components/Registration/AcceptanceAnnouncement.vue"),
  },
  name: "Registration",
};
</script>
